import React from 'react';

import Section from '../section';
import SummaryItem from '../summary-item';

const SectionAchievements = ({ achievements }) => {
  if (!achievements.length) return null;

  return (
    <Section title="Achievements/Awards">
      {achievements.map((achievement) => (
        <SummaryItem
          key={achievement.name}
          name={achievement.name}
          description={achievement.description}
          prize={achievement.prize}
          date={achievement.date}
          link={achievement.link}
        />
      ))}
    </Section>
  );
};

export default SectionAchievements;
